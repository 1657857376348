@media (max-width: 1150px) {
  .toggleKeyword {
    font-size: 12px !important;
  }

  .openNotes {
    left: 60% !important;
  }

  .openInPlayer {
    left: 62% !important;
  }
}

* {
  font-style: normal !important;
  font-family: "Inter" !important;
  font-size: "14px" !important;
  font-weight: "500" !important;
}

.facets {
  height: inherit;
}

.actionsContainer {
  margin-top: 10px;
}

.detailTiltles {
  font-size: 14px;
  color: #4a90e2;
  margin: 0px 0px 6px 0px;
}

.descriptionBox {
  margin-bottom: 6px;
}

.notesTitle {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  margin: 0px 0px 6px 0px;
}

.actionsContainer {
  margin-top: 10px;
}

.result_card {
  font-style: normal;
  font-family: "Inter" !important;
  font-size: "14px" !important;
  font-weight: "500" !important;
  line-height: 1.7 !important;
  min-width: -webkit-fill-available !important;
  min-width: -moz-available !important;
  margin: 4px;
  height: fit-content;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.102751) !important;
  border-radius: 10px;
  padding: 8px 8px 8px 2px;
}

.result_card mark {
  background-color: #53c8ff !important;
  border-radius: 5px;
}

.result_card:hover {
  color: #000 !important;
}

img.audioIcon {
  content: url(../assets/svgs/gn-audio_call.svg);
  display: block;
  margin: auto;
  width: 24px;
  height: 24px;
}

img.playIcon {
  content: url(../assets/imgs/playIcon.png);
  width: 38px;
  height: 38px;
}

img.videoIcon {
  content: url(../assets/svgs/gn-video_call.svg);
  display: block;
  margin: auto;
  width: 24px;
  height: 20px;
}

.timerIcon {
  margin: 0px 5px 0px 5px;
  color: #1976d2;
}

.openInPlayer {
  margin-left: auto;
  color: #0070d2;
  border: none;
  font-size: 14px;
}

.moreDetails {
  color: #0070d2 !important;
  position: absolute;
  bottom: 5px;
  border: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
}

.openNotes {
  color: #40a9ff;
  border: none;
  display: inline-block;
  position: absolute;
  top: 85%;
  left: 65%;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 5px;
}

button.rheostat-handle {
  width: 18px !important;
  height: 18px !important;
}

.rangeTitle {
  font-size: 14px !important;
}

.rangeSlider {
  margin-bottom: 0px !important;
}

:global(.css-1wwr2lr-histogramContainer) {
  height: 40px !important;
}

.rangeInput button span {
  background: transparent !important;
}

.rangeInput button {
  width: 18px !important;
  height: 18px !important;
  top: -8px !important;
}

.rangeInput h2 {
  font-size: 14px !important;
}

.searchBar {
  min-width: 370px !important;
  max-width: 370px !important;
  display: inline-block !important;
  position: relative !important;
  margin-right: 15% !important;
  margin-bottom: 20px !important;
}

.searchBar input {
  background-color: inherit;
  height: 44px !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 10px;
}

.searchBar input:focus {
  border: 2px solid #1976d2 !important;
}

.searchBar div div {
  box-shadow: unset !important;
}

.datePicker div div:nth-child(odd) {
  border: 0px !important;
}

.datePicker div div input:nth-child(odd) {
  height: 44px !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 10px !important;
}

.datePicker div div input:focus {
  border: 2px solid #1976d2 !important;
}

.chips {
  margin-bottom: 20px;
}

.chips a {
  border-radius: 16px !important;
  margin-bottom: 15px !important;
  padding: 6px 16px !important;
  background-color: #eceff1;
}

.chips a span:last-of-type {
  border: none !important;
}

.modalHeading {
  font-size: 20px;
  font-weight: 400;
}

.AgentName {
  display: inline-flex !important;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
}

.esScoreDiv {
  position: absolute;
  right: 0;
  margin-right: 10px;
  display: inline-flex;
}

.gryphScore {
  padding: 6px;
  border-radius: 10px;
  color: #ffffff;
  text-align: center;
  width: 40px;
}

.gryphScore:hover {
  cursor: pointer;
}

.complianceResultDiv:hover {
  cursor: pointer;
}

@media (max-width: 1600px) {
  .mediumDropdownTitle {
    width: 60% !important;
  }
}

.mediumDropdownTitle {
  margin-bottom: 16px;
  width: 70%;
}

.mediumDropdownTitle h2 {
  font-size: 14px !important;
}

.mediumDropdownTitle button[type="button"] {
  border-radius: 10px !important;
  border: 1px solid #c4c4c4 !important;
}

.mediumDropdownTitle button[type="button"]:focus-within {
  border: 2px solid #1976d2 !important;
}

.dropdownTitle h2 {
  font-size: 14px !important;
}

.dropdownTitle button[type="button"] {
  border-radius: 10px !important;
  border: 1px solid #c4c4c4 !important;
}

.dropdownTitle button[type="button"]:focus-within {
  border: 2px solid #1976d2 !important;
}

.complianceDiv {
  display: inline-flex;
  width: 100%;
  margin-bottom: 16px;
}

.actions {
  margin: 0px 15px;
}

.actionsText {
  padding-left: 10px;
}

.errorMsg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.customErrorMessage {
  color: #495057;
  font-size: 20px;
  font-weight: 600;
  text-transform: none;
}

.customErrorIcon {
  color: #1971c2;
  width: 50%;
  height: 50%;
}

.customErrorContainer {
  background-color: #e5e5e5;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  text-transform: capitalize;
}

.baseDiv {
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
}

.rowDiv {
  padding: 20px;
  text-align: left;
  overflow: hidden;
  height: inherit;
  width: inherit;
}

.elasticSearchDivider {
  margin: 12px 0px;
}

.searchSwitch {
  font-size: 14px !important;
}

.divRangeInputs {
  display: inline-flex !important;
  margin: 8px;
}

.spanRangeInputs {
  margin: 8px;
}

.rangeSliderLabels {
  display: none;
}
